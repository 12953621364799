import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            dndModel: hook((ctx) => {
              const lessonList = React.useMemo(() => ctx.get('form.values.lessons'), [
                ctx.get('form.values.lessons.length'),
              ]);
              const [itemList, setItemList] = React.useState(lessonList);
              const [current, setCurrent] = React.useState(!itemList ? null : 0);
              React.useEffect(() => {
                setItemList(lessonList);
              }, [ctx.get('form.values.lessons.length')]);

              const handleDrop = (droppedItem) => {
                // Ignore drop outside droppable container
                if (!droppedItem.destination) return;
                var updatedList = [...itemList];
                // Remove dragged item
                const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
                // Add dropped item
                updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
                // Update State
                setItemList(updatedList);
              };
              const activeLesson = () => {
                const findData = _.find(itemList, (item) => {
                  return item.id === current;
                });

                return findData;
              };
              return {
                current,
                setCurrent,
                activeLesson,
                itemList,
                handleDrop,
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
