import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchTemplate: false }))),
            getTemplateId: hook(() => {
              const route = useRoute();
              const paramsData = _.get(route, 'params.id', '');
              return paramsData;
            }),

            '@item': hook((ctx) => {
              const template_id = ctx.get('getTemplateId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!template_id) return null;
                    const client = await getClient();
                    const template_data = await client.request(
                      gql`
                        query GetTemplateInfo($id: uuid!) {
                          b2b_course_template_by_pk(id: $id) {
                            id
                            account_id
                            name
                            description
                            link_urls
                            materials
                            photo_url
                            course_template_lessons {
                              id
                              name
                              description
                              link_urls
                              materials
                              photo_url
                            }
                          }
                        }
                      `,
                      { id: template_id }
                    );
                    return template_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [template_id]
              );

              return _.get(data, 'b2b_course_template_by_pk', {});
            }),

            getAccountZone: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const account_profile_id = _.get(routeParams, 'account_profile.id', '');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_profile_id) return null;
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetAccountZone($id: uuid!) {
                          b2b_account_profile_by_pk(id: $id) {
                            holiday_zone
                          }
                        }
                      `,
                      { id: account_profile_id }
                    );
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_profile_id]
              );

              return _.get(data, 'b2b_account_profile_by_pk', '');
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return _.isEmpty(ctx.get('@item'));
          }),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !_.isEmpty(ctx.get('@item'))),
        },
      ],
    ],
  },
});

export default bindData;
