import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import B2BAddTeacherModal from '@uz/unitz-tool-components/B2BAddTeacherModal';
import { Button } from '@uz/unitz-components-web/Button';
import { ctx } from '@vl/redata';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import gstyles from '@vl/gstyles';
import ToolSearchInput from '@uz/unitz-tool-components/ToolSearchInput';
import { Form, FormItem, Select } from 'formik-antd';
import { Row, Col, Table } from 'antd';
import styled from 'styled-components';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const CustomFormItem = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const CustomTable = styled(Table)`
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        border-radius: 8px;
        .ant-table-container {
          .ant-table-content {
            table > thead > tr:first-child th:last-child {
              border-top-right-radius: 8px;
            }
            table > thead > tr:first-child th:first-child {
              border-top-left-radius: 8px;
            }
            table > thead > tr > th {
              background-color: ${gstyles.colors.background2};
            }
          }
        }
      }
    }
  }
`;

const View8 = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="component">
      <div className="m-4">
        <div className="bg-white500 rounded-lg">
          <FormProvider form={ctx.get('filterForm')}>
            <Form {...layout} className="teacherFilterMenu">
              <Row gutter={8}>
                <Col span={22}>
                  <Row gutter={gutter}>
                    <Col span={8}>
                      <ToolSearchInput
                        name="search"
                        label={ctx.apply('i18n.t', 'ZoomToolMember.Form.search_label')}
                        placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Form.search_placeholder')}
                        onClick={ctx.get('filterForm.handleSubmit')}
                      />
                    </Col>
                    <Col span={8}>
                      <CustomFormItem>
                        <FormItem
                          name="type"
                          label={
                            <span className="text-sm font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.type')}
                            </span>
                          }
                        >
                          <CustomInput>
                            <Select
                              size="medium"
                              name="type"
                              suffixIcon={gstyles.icons({
                                name: 'arrow-down3',
                                size: 24,
                                fill: gstyles.colors.sub,
                                className: '-mt-2 -ml-2',
                              })}
                              // onChange={ctx.get('handleChangeSubmit')}
                            >
                              {_.map(ctx.get('roleOptions'), (item) => (
                                <Select.Option value={_.get(item, 'role_id', '')} key={_.get(item, 'role_id', '')}>
                                  {_.get(item, 'name', '')}
                                </Select.Option>
                              ))}
                            </Select>
                          </CustomInput>
                        </FormItem>
                      </CustomFormItem>
                    </Col>
                    <Col span={8}>
                      <CustomFormItem>
                        <FormItem
                          name="status"
                          label={
                            <span className="text-sm font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolMember.Form.status_label')}
                            </span>
                          }
                        >
                          <CustomInput>
                            <Select
                              size="medium"
                              name="status"
                              suffixIcon={gstyles.icons({
                                name: 'arrow-down3',
                                size: 24,
                                fill: gstyles.colors.sub,
                                className: '-mt-2 -ml-2',
                              })}
                              // onChange={ctx.get('handleChangeSubmit')}
                              placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Form.status_placeholder')}
                            >
                              {_.map(ctx.get('statusOptions'), (item) => (
                                <Select.Option value={_.get(item, 'value', '')} key={_.get(item, 'value', '')}>
                                  {_.get(item, 'label', '')}
                                </Select.Option>
                              ))}
                            </Select>
                          </CustomInput>
                        </FormItem>
                      </CustomFormItem>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ display: 'flex' }} className="justify-end items-end" span={2}>
                  <Button
                    style={{ marginTop: '5px', border: `1px solid ${gstyles.colors.border}` }}
                    size="medium"
                    name="ink"
                    block
                    onClick={ctx.get('filterForm.clearFilter')}
                  >
                    <span className="text-brandb500 text-base font-semibold text-center">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Form.clear_all_button')}
                    </span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </div>
        <div className="py-6 flex justify-between items-center">
          <div className="text-main font-semibold text-base">{ctx.apply('i18n.t', 'ZoomToolCourse.teacher')}</div>
          <Row>
            <Col span={12}>
              <ModalProvider>
                <Button
                  type="primary"
                  size="medium"
                  onClick={() => {
                    ctx.apply('modalModel.show', () => {
                      return <B2BAddTeacherModal />;
                    });
                  }}
                >
                  <div className="flex items-center">
                    <div className="text-white500 font-semibold">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_a_teacher')}
                    </div>
                    <div>
                      {gstyles.icons({
                        name: 'plus',
                        size: 14,
                        fill: gstyles.colors.white500,
                        className: 'mx-2',
                      })}
                    </div>
                  </div>
                </Button>
              </ModalProvider>
            </Col>
          </Row>
        </div>

        <CustomTable
          columns={ctx.get('tableColumn.columns')}
          dataSource={ctx.get('tableColumn.dataSource', [])}
          rowKey={(rec) => _.get(rec, 'id')}
          loading={ctx.get('paginationModel.isLoading')}
          pagination={ctx.get('tableColumn.pagination')}
          onChange={ctx.get('paginationModel.onChange')}
          size="middle"
        />
      </div>
    </DIV>
  );
};

export default displayName(View8);
