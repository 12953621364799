import React from 'react';
import { bindings } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
});

export default bindData;
