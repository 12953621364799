import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import FormUseCourseTemplate from '@uz/unitz-tool-components/FormUseCourseTemplate';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <DIV className="isLoading">
          <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
            <LoadingScreen />
          </div>
        </DIV>
        <DIV className="notLoading">
          <div className="p-4">
            <div className="mb-3 text-main uppercase font-semibold text-base">
              {ctx.apply('i18n.t', 'ZoomToolCourse.UseTemplate.title')}{' '}
              {!!ctx.get('@item.name') ? `: ${ctx.get('@item.name')}` : ''}
            </div>
            <div className="p-4 bg-white500 rounded-lg shadow-md">
              <FormUseCourseTemplate />
            </div>
          </div>
        </DIV>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
