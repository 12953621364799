import React from 'react';
import { bindings, hook } from '@vl/redata';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import MemberModel from '@uz/unitz-models/B2BMemberModel';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import _ from 'lodash';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { decode } from '@vl/mod-utils/jwt';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Checkbox } from 'antd';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            filterForm: hook((ctx) => {
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  status: '',
                  search: '',
                },

                onSubmit: async (values, actions) => {
                  try {
                    const memberFilterPagingModel = ctx.apply('REF.getRef', 'MemberPagingModel');
                    if (memberFilterPagingModel) {
                      await memberFilterPagingModel.onLoadPage(0);
                    }
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                clearFilter: async () => {
                  await form.resetForm();
                  await form.setFieldValue('search', '');
                  form.submitForm();
                },
              });

              return form;
            }),

            statusOptions: hook(() => {
              const status = [
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: 'active',
                  label: 'Active',
                },
                {
                  value: 'disabled',
                  label: 'Disabled',
                },
              ];
              return status;
            }),

            getFilterArgs: hook((ctx) => {
              const statusVars = {
                active: true,
                disabled: false,
                all: '',
              };
              return () => {
                const values = ctx.get('filterForm.values', {});
                const status = _.get(values, 'status', '');
                const args = {
                  search: _.get(values, 'search', ''),
                  ...(status ? { status: _.get(statusVars, status, '') } : {}),
                };
                if (status === 'all') _.unset(args, 'status');
                return args;
              };
            }),

            handleChangeSubmit: hook((ctx) => {
              return React.useMemo(() => _.debounce(() => ctx.apply('filterForm.handleSubmit'), 599), []);
            }),

            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              const account_id = ctx.apply('accountModel.getAccountId');
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModel = React.useMemo(() => {
                const condition = `where: {
                  account_id: {_eq: "${account_id}"}
                  member_roles: {role_id: {_eq: "teacher"}}
                }`;
                const dataQuery = `
                  b2b_search_member (args: $args, ${condition}, order_by: {created_at: desc_nulls_last}){
                    id
                    is_active
                    user_id
                    member_profile {
                      id
                      first_name
                      last_name
                      email
                      avatar_url
                      phone
                      date_of_birth
                      place_of_birth
                      phone
                      note
                    }
                    user {
                      profile {
                        display_name
                        avatar_url
                        email
                      }
                    }
                    member_roles{
                      role {
                        role_id
                        name
                      }
                    }
                  }
                `;

                const pagingModel = PaginationModel.fromConfig({
                  Model: MemberModel,
                  dataQuery,
                  aggQuery: `
                    b2b_search_member_aggregate (args: $args, ${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 10,
                  varsList: '$args: b2b_search_member_args!',
                  getVars: () => {
                    return {
                      args: ref.current.getFilterArgs(),
                    };
                  },
                });
                return pagingModel;
              }, [ref, account_id]);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };
              ctx.apply('REF.setRef', 'MemberPagingModel', pagination);
              return pagination;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return (
                      <div>{ctx.get('paginationModel.page') * ctx.get('paginationModel.pageSize') + index + 1}</div>
                    );
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.teacher')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-3">
                          <div>{MemberFormatter.fullName(ctx)(val)}</div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.email')}
                    </div>
                  ),
                  key: 'email',
                  render: (val) => <div>{MemberFormatter.email(ctx)(val) || ''}</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.course_in_progress')}
                    </div>
                  ),
                  render: (val) => <div className="text-center">3/15</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.lesson_in_progress')}
                    </div>
                  ),
                  key: 'lessonInProgress',
                  render: (val) => <div className="text-center">5/122</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.attendance')}
                    </div>
                  ),
                  render: (val) => <div className="text-center">-/-/-</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.col_name')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div>
                        {!!_.get(val, 'is_active')
                          ? ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.active')
                          : ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.disabled')}
                      </div>
                    );
                  },
                },

                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.add_to_course')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return (
                      <DIV>
                        <div className="text-center">
                          <Checkbox
                            name={_.get(record, 'id', '')}
                            checked={_.map(
                              ctx.get(`form.values.lessons[${ctx.get('lesson_index')}].teachers`),
                              (e) => e.id
                            ).includes(record.id)}
                            onClick={(e) =>
                              ctx.apply('form.handleChangeCheckbox', {
                                event: e,
                                value: { ...record, role: 'Assistant' },
                                field: `lessons[${ctx.get('lesson_index')}].teachers`,
                              })
                            }
                          />
                        </div>
                      </DIV>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblLessonAssistantsModal'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');
              const tableName = ctx.get('tableName', 'tblLessonAssistantsModal');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
