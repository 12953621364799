import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import CustomTable from '@uz/unitz-components-web/CustomTable';

const View8 = () => {
  return (
    <DIV className="component">
      <CustomTable
        columns={ctx.get('tableTeacherColumn.columns')}
        dataSource={ctx.get('tableTeacherColumn.dataSource', [])}
        rowKey={(rec) => _.get(rec, 'id')}
        pagination={false}
      />
    </DIV>
  );
};

export default displayName(View8);
