import React from 'react';
import _ from 'lodash';
import { Field } from 'formik-antd';
import Loadable from '@loadable/component';

const Editor = Loadable(() => import('@uz/unitz-components-web/QuillInput/quill'));

export const InputQuillEditor = ({ name, validate, fast, onChange, onBlur, ...restProps }) => {
  const ref = React.useRef({});
  const onChangeHandle = React.useMemo(
    () =>
      _.debounce((value) => {
        ref.current.form && ref.current.form.setFieldValue(name, value != null ? value.valueOf() : value);
        onChange && onChange(value);
      }, 500),
    []
  );
  const onBlurHandle = (value) => {
    ref.current.form && ref.current.form.setFieldTouched(name);
    onBlur && onBlur(value);
  };

  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form }) => {
        ref.current.form = form;
        return <Editor {...restProps} value={value} onChange={onChangeHandle} onBlur={onBlurHandle} />;
      }}
    </Field>
  );
};

export default InputQuillEditor;
