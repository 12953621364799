import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import gstyles from '@vl/gstyles';
import Avatar from '@uz/unitz-components-web/Avatar';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            allTeachersColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.teacher')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            <div className="text-xs"> Type: {_.get(val, 'role', '')}</div>
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 300,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() =>
                            ctx.apply('stepForm_1.array.remove', `lessons[${ctx.get('lesson_index')}].teachers`, index)
                          }
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblLessonTeachers'),

            tableTeacherColumn: hook((ctx) => {
              const filter = _.filter(ctx.get('form.values.lessons'), (lesson) => {
                return ctx.get('dndModel.current') === lesson.id;
              });

              const data = _.get(filter, '0.teachers', []);
              const tableName = ctx.get('tableName', 'tblLessonTeachers');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allTeachersColumns');

              const columns = _.filter(ctx.get('allTeachersColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
