import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const View8 = ({ list, removable, form_name }) => {
  return (
    <DIV className="component">
      <div>
        {!!_.get(list, 'length') && (
          <div>
            {_.map(list, (item, index) => (
              <div className="p-4">
                {console.log(item)}
                <Row align="middle">
                  <Col span={2}>{index + 1}</Col>
                  <Col span={!!removable ? 18 : 22}>
                    <div className="identify-container">
                      <div className="table-cell align-top">
                        <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(item)} />
                      </div>

                      <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                        <div className="flex flex-col">
                          <div className="text-xs text-brandb500 underline">{MemberFormatter.fullName(ctx)(item)}</div>
                          <div className="text-xs">{_.get(item, 'role', '')}</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {removable && (
                    <Col span={4}>
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('form.array.remove', `${form_name}`, index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                <div className="mt-4">
                  <span className="text-sub font-semibold text-xs">
                    {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}:{' '}
                  </span>
                  <span className="text-sub font-normal text-xs">{MemberFormatter.email(ctx)(item)}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </DIV>
  );
};

export default displayName(View8);
